import Rollbar from "rollbar";

export const rollbar = new Rollbar({
  accessToken: process.env.ROLLBAR_SERVER_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
  ignoreDuplicateErrors: true,
  captureLambdaTimeouts: true,
  captureDeviceInfo: true,
  includeItemsInTelemetry: true,
  nodeSourceMaps: true,
  stackTraceLimit: 100,
  captureUsername: true,
  addErrorContext: true,
  autoInstrument: true,
  enabled: process.env.NODE_ENV === "production",
  version: "1.0.0",
  codeVersion: "1.0.0",
});
